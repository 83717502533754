<template>
    <div v-if="isLoading" class="spinner-wrapper">
        <Spinner />
    </div>
    <div v-else>
        <Datatable :data="broadcasts" :columns="tableColumns">
            <template v-slot:type="{ item }">
                {{ getBroadcastType(item) }}
            </template>

            <template v-slot:actions="{ item }">
                <TableRowActions
                    :menu-actions="[
                        {
                            label: 'Get Email Campaign Stats',
                            action: 'get-email-campaign-stats',
                            icon: 'email-report',
                        },
                        {
                            label: 'Convo Edit',
                            action: 'convo-edit',
                            icon: 'convo',
                        },
                    ]"
                    @action="onRowAction(item, $event)"
                />
            </template>
        </Datatable>

        <Paging
            :total="broadcasts.total"
            :size="broadcasts.size"
            :page="broadcasts.page"
            @pageSelected="selectPage"
        />
    </div>
</template>

<script>
import config from '@/config';
import { LoadingFlag, BroadcastType } from '@/store/enums';
import Datatable from '../components/Datatable.vue';
import Paging from '../components/Paging';
import Spinner from '@/components/ui/Spinner';
import TableRowActions from '@/components/TableRowActions';

const broadcastTypeMap = {
    [BroadcastType.ConvoServiceEmail]: 'Service Email',
    [BroadcastType.ConvoTestEmail]: 'Test Email',
    [BroadcastType.ConvoReminderEmail]: 'Reminder Email',
    [BroadcastType.ConvoTestReminderEmail]: 'Test Reminder Email',
    [BroadcastType.EmailAutomation]: 'Email Automation',
};

export default {
    name: 'ChannelBroadcasts',
    components: {
        Datatable,
        TableRowActions,
        Paging,
        Spinner,
    },
    props: {
        broadcasts: {
            type: Object,
            default: () => ({
                items: [],
                page: 0,
                size: 0,
                total: 0,
            }),
        },
        channelCode: {
            type: String,
            required: true,
        },
        numberOfRows: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            LoadingFlag,
            tableColumns: [
                {
                    value: 'convoName',
                    header: 'Convo Name',
                    width: '20%',
                    type: 'text',
                },
                {
                    value: 'audienceId',
                    header: 'Audience ID',
                    type: 'text',
                    width: '6%',
                },
                {
                    value: 'createdAt',
                    header: 'Created at',
                    type: 'date',
                    width: '10%',
                    format: config.dateTimeFormat,
                },
                {
                    value: 'status',
                    header: 'Status',
                    type: 'text',
                    width: '6%',
                },
                {
                    value: 'type',
                    header: 'Type',
                    type: 'slot',
                    width: '8%',
                },
                {
                    value: 'segmentReach',
                    header: 'Segment Reach',
                    type: 'text',
                    width: '6%',
                },
                {
                    value: 'convoStarts',
                    header: 'Convo Starts',
                    width: '6%',
                    type: 'text',
                },
                {
                    value: 'convoStartsPercentage',
                    header: 'Convo Starts %',
                    width: '8%',
                    type: 'text',
                },
                {
                    value: 'emailDeliveryCount',
                    header: 'Email Deliveries',
                    width: '8%',
                    type: 'text',
                },
                {
                    value: 'openRecipients',
                    header: 'Open Recipients',
                    width: '6%',
                    type: 'text',
                },
                {
                    value: 'openRecipientsPercentage',
                    header: 'Open Recipients %',
                    width: '8%',
                    type: 'text',
                },
                {
                    value: 'actions',
                    header: '',
                    type: 'slot',
                    width: '8%',
                    class: 'table-row-actions',
                },
            ],
            broadcastType: BroadcastType,
        };
    },
    computed: {
        isLoading() {
            return (
                this.$wait.is(LoadingFlag.BroadcastsGet) ||
                this.$wait.is(LoadingFlag.BroadcastCampaignGetOne)
            );
        },
    },
    methods: {
        rowOnClick(event, item) {
            this.$router.push({
                name: 'publishing',
                params: { channelCode: item.channelCode, convoId: item.convoId },
            });
        },

        selectPage(event, page) {
            this.$emit('getBroadcasts', {
                channelCode: this.channelCode,
                page,
                size: this.numberOfRows,
            });
        },
        getBroadcastType(item) {
            return broadcastTypeMap[item.type];
        },
        onRowAction(item, action) {
            if (action === 'get-email-campaign-stats') {
                this.$emit('getCampaignMetric', {
                    broadcastId: item.id,
                    campaignId: item.campaignId,
                    appId: item.appId,
                });
            }
            if (action === 'convo-edit') {
                this.$router.push({
                    name: 'messages',
                    params: { channelCode: item.channelCode, convoId: item.convoId },
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 500px;
}
</style>
