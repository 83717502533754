<template>
    <div class="broadcast-list">
        <PageHeading :breadcrumbs="breadcrumbs" title="Broadcasts" />

        <AutoComplete
            id="channel"
            class="m-b-4 w-25"
            name="channel"
            label="Channel"
            :items="channelAutocompleteItems"
            icon-color="#B3E0DE"
            placeholder="Select channel"
            @input="onChannelInput"
        />

        <ChannelBroadcasts
            v-if="showBroadcastList"
            :broadcasts="channelDetails.broadcasts"
            :channel-code="channelCode"
            :number-of-rows="10"
            @getBroadcasts="getBroadcasts"
            @getCampaignMetric="getCampaignMetric"
        />
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { LoadingFlag } from '@/store/enums';
import { GetChannelsRequest } from '@/store/models/dtos';
import { ChannelClient } from '@/api/channel';
import { Breadcrumb } from '@/store/models/breadcrumb.dto.ts';
import PageHeading from '@/components/ui/PageHeading';
import AutoComplete from '@/components/forms/AutoComplete';
import ChannelBroadcasts from '@/components/ChannelBroadcasts';

export default {
    name: 'BroadcastList',
    components: {
        AutoComplete,
        PageHeading,
        ChannelBroadcasts,
    },
    props: {
        navIsOpened: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            breadcrumbs: [],
            channelOptions: [],
            showBroadcastList: false,
            channelCode: '',
        };
    },
    computed: {
        ...mapGetters('channel', {
            channelDetails: 'channelDetails',
        }),
        channelAutocompleteItems() {
            return this.channelOptions.map((opt) => opt.text);
        },
        isLoading() {
            return (
                this.$wait.is(LoadingFlag.BroadcastsGet) ||
                this.$wait.is(LoadingFlag.BroadcastCampaignGetOne)
            );
        },
    },
    watch: {
        channelCode(value) {
            this.getChannelBroadcast(value);
        },
    },
    created() {
        this.setBreadcrumbs();
        this.getChannels();
    },
    methods: {
        ...mapActions('channel', {
            getBroadcasts: 'getBroadcasts',
            getCampaignMetric: 'getCampaignMetric',
        }),
        onChannelInput(channelName, optionIndex) {
            const channel = this.channelOptions[optionIndex];
            if (channel) {
                this.channelCode = channel.id;
            }
        },
        async getChannels() {
            const request = new GetChannelsRequest();
            request.page = 1;
            request.size = 100;
            const result = await ChannelClient.getChannels(request);
            const channels = result.items.map((channel) => ({
                id: channel.code,
                text: channel.name,
            }));
            this.channelOptions = channels;
        },
        setBreadcrumbs() {
            this.breadcrumbs = [new Breadcrumb('Broadcasts', { name: 'broadcasts' })];
        },
        async getChannelBroadcast(channelCode) {
            this.getBroadcasts({ size: 10, page: 1, channelCode });
            this.showBroadcastList = true;
        },
    },
};
</script>
<style lang="scss" scoped>
.broadcast-list {
    width: 100%;

    .datatable {
        position: relative;
        /deep/ .row {
            cursor: pointer;
        }
    }
}
</style>
