var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('div',{staticClass:"spinner-wrapper"},[_c('Spinner')],1):_c('div',[_c('Datatable',{attrs:{"data":_vm.broadcasts,"columns":_vm.tableColumns},scopedSlots:_vm._u([{key:"type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getBroadcastType(item))+" ")]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('TableRowActions',{attrs:{"menu-actions":[
                    {
                        label: 'Get Email Campaign Stats',
                        action: 'get-email-campaign-stats',
                        icon: 'email-report',
                    },
                    {
                        label: 'Convo Edit',
                        action: 'convo-edit',
                        icon: 'convo',
                    } ]},on:{"action":function($event){return _vm.onRowAction(item, $event)}}})]}}])}),_c('Paging',{attrs:{"total":_vm.broadcasts.total,"size":_vm.broadcasts.size,"page":_vm.broadcasts.page},on:{"pageSelected":_vm.selectPage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }